<template>
  <v-app id="inspire" class="main-layout">
    <Message/>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      :mini-variant="miniSize"
      mini-variant-width="64"
      class="custom-nav"
    >
      <v-layout column style="height: 100%">
        <!--        <div class="mt-4 ml-3 mb-2">-->
        <!--          <v-btn color="primary" :fab="miniSize" :small="miniSize" style="border-radius: 20px">-->
        <!--            <v-icon>mdi-plus</v-icon>-->
        <!--            <span v-if="!miniSize">{{ $t('Compose') }}</span>-->
        <!--          </v-btn>-->
        <!--        </div>-->
        <v-spacer style="overflow-y: auto; overflow-x: hidden">
          <v-list dense class="py-0">
            <template v-for="(item, index) of menu" v-if="!item.hide">
              <v-list-group
                v-if="item.children"
                v-model="item.model"
                :prepend-icon="item.icon"
                class="mb-2"
                :title="$t(item.text)"
                :key='index'
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </template>
                <v-list-item
                  v-for="(child, idx) of item.children"
                  :key="idx"
                  :title="$t(child.text)"
                  :to="child.link"
                  v-if="!child.hide"
                >
                  <v-list-item-icon>
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="pl-2">{{
                    $t(child.text)
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-item v-else :to="item.link" style="height: 45px">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-spacer>
        <v-btn
          small
          depressed
          @click="miniSize = !miniSize"
          style="margin-bottom: 2px"
        >
          <v-icon>{{
            miniSize ? "mdi-chevron-right" : "mdi-chevron-left"
          }}</v-icon>
        </v-btn>
      </v-layout>
    </v-navigation-drawer>
    <v-app-bar app clipped-left color="bar" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <img
        alt="logo"
        src="https://eofactory.ai/image/logo-white-2a642acb6b7ceb83fae385c1c7524d5f.png"
        height="42px;"
        class="ml-2 mr-4"
        style="cursor: pointer"
        @click="goToLink('/app')"
      />
      <!--      <v-app-bar-title class="hidden-sm-and-down" style="font-weight: 600">{{ $t('Email') }}</v-app-bar-title>-->
      <v-spacer></v-spacer>
      <div class="space-around">
<!--        <v-btn icon @click="toggleDark">-->
<!--          <v-icon>mdi-white-balance-sunny</v-icon>-->
<!--        </v-btn>-->
<!--        <Language></Language>-->
        <AvatarPopOver></AvatarPopOver>
      </div>
    </v-app-bar>
    <v-main class="background">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "@/store/ults";
import AvatarPopOver from "@/components/AvatarPopOver";
import Language from "@/components/Language";
import { toggleDark } from "@/plugins/vuetify";
import Message from "@/components/Message";

export default {
  components: {
    Message,
    AvatarPopOver,
    Language,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    miniSize: true,
  }),
  computed: {
    ...mapState("main", ["menu"]),
  },
  async created() {},
  methods: {
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({ path: link });
    },
    toggleDark,
  },
};
</script>

<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}

.main-layout .custom-list-item {
  padding-left: 30px;
}
</style>
<style>
html,
body,
.inspire {
  overflow-y: hidden;
  font-family: "Roboto", sans-serif;
}
</style>
