import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../views/MainLayout.vue'
import NotFound from '../views/NotFound'
import {checkAuth} from './auth'
import LandingPage from "@/views/landingPage/LandingPage";

Vue.use(VueRouter)

const routes = [
  {
    beforeEnter: checkAuth,
    path: '/',
    component: LandingPage,
  },
  {
    beforeEnter: checkAuth,
    path: '/app',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      }, {
        path: 'orders',
        name: 'Order',
        component: () => import(/* webpackChunkName: "about" */ '../views/Order.vue')
      }
    ]
  },
  {
    path: '*',
    component: NotFound,
    name: 'Not Found'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
