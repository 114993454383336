import api from '@/api'
import config from '@/config.json'
import Store from '@/store'

export async function getMe() {
  return await api.get(`${config.backend_php}/me`)
}

export async function authLogin(email, password) {
  let result = await api.post('/api/auth/login', {
    email,
    password
  })
  api.setToken(result.data.access_token)
}

export async function authLogout() {
  await api.post(`${config.backend_php}/logout`)
  api.deleteToken()
  window.location.href = '/'
}

export async function getCountries() {
  return await api.get(`${config.backend_php}/territories/country`)
}

export async function geocoder(keyWord) {
  return await api.get(`${config.backend_php}/adminitrative/autocomplete?search=${keyWord}`)
}

export async function getThumbnail(image) {
  return await api.post(`${config.backend_php}/ig/discovery/api/image/thumbnail`, {
      ...image,
      api_key: Store.state.auth.currentUser.latconnect60_api_key
  });
}

export async function getOrders(param) {
  return await api.get(`${config.backend_php}/ig/v2/orders`, {
    page: param.page,
    per_page: param.per_page,
    owner: true,
    workspace_id: null
  });
}

export async function importImageToEOF(image) {
  return await api.post(`${config.backend_php}/workspaces/${image.workspace_id}/imageries_guru`, image);
}

export async function downloadOrderImage(id) {
  return await api.get(`${config.backend_php}/ig/v2/images/${id}/download`);
}

export async function search(param) {
  return await api.post(`${config.backend_php}/ig/discovery/api/image/search`, {
    ...param,
    api_key: Store.state.auth.currentUser.latconnect60_api_key
  })
}

export async function getStates(code) {
  return await api.get(`${config.backend_php}/territories/state/${code}`)
}

export async function getDistricts(code) {
  return await api.get(`${config.backend_php}/territories/district/${code}`)
}

export async function getSubDistricts(code) {
  return await api.get(`${config.backend_php}/territories/sub_district/${code}`)
}

export async function getStateDetail(code) {
  return await api.get(`${config.backend_php}/territories/state_detail/${code}`)
}

export async function getStateDetails(stateIds) {
  return await api.post(`${config.backend_php}/territories/state_details`, {
    state_ids: stateIds
  })
}

export async function mergeStates(form) {
  return await api.post(`${config.backend_php}/state/merge`, form)
}

export async function mergeDistricts(form) {
  return await api.post(`${config.backend_php}/district/merge`, form)
}

export async function createDataAlert(data) {
  return await api.post(`${config.backend_php}/ig/v2/data-alerts`, data);
}

export async function getSavedSearches(params) {
  return await api.get(`${config.backend_php}/ig/v2/saved-searches`, params);
}

export async function createSavedSearch(data) {
  return await api.post(`${config.backend_php}/ig/v2/saved-searches`, data);
}

export async function deleteSavedSearch(id) {
  return await api.delete(`${config.backend_php}/ig/v2/saved-searches/${id}`);
}

export async function order(data) {
  return await api.post(`${config.backend_php}/ig/v2/orders`, data)
}

export async function getListWorkspaces() {
  return await api.get(`${config.backend_php}/workspaces`)
}

export async function uploadShapeFile(formData) {
  return await api.post(`${config.backend_php}/v1/public/geojson/convert`, formData)
}

export async function addAOI(form) {
  return await api.post(`${config.backend_php}/ig/v2/aois`, form)
}

export async function fetchAOIs() {
  return await api.get(`${config.backend_php}/ig/v2/aois`)
}

export async function updateAOI(form) {
  return await api.put(`${config.backend_php}/ig/v2/aois/${form.id}`, form.data)
}

export async function deleteAOI(form) {
  return await api.delete(`${config.backend_php}/ig/v2/aois/${form.id}`, form.data)
}

//Latconnect60 Order
export async function getLatconnect60Orders(param) {
  return await api.get(`${config.backend_php}/lc60/v1/orders`, {
    page: param.page,
    per_page: param.per_page,
    api_key: Store.state.auth.currentUser.latconnect60_api_key
  });
}

export async function latconnect60Order(data) {
  return await api.post(`${config.backend_php}/lc60/v1/orders`, {
    ...data,
    api_key: Store.state.auth.currentUser.latconnect60_api_key
  })
}

export async function downloadLatconnect60OrderImage(location) {
  return await api.get(`${location}${Store.state.auth.currentUser.latconnect60_api_key}`);
}

export async function saveApiKey(api_key) {
  return await api.put(`${config.backend_php}/user`, {
    latconnect60_api_key: api_key
  });
}