<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.mapboxgl-ctrl-logo{
  display: none !important;
}
</style>
