import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/style/main.scss"
import mapboxgl from "mapbox-gl/dist/mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { vuetify } from './plugins/vuetify'
import { vueI18n } from './plugins/i18n'
import ScrollAnimation from "@/ultis/ScrollAnimation";


Vue.directive('scrollAnimation', ScrollAnimation)

Vue.config.productionTip = false
window.mapboxgl = mapboxgl
new Vue({
  router,
  store,
  vuetify,
  i18n: vueI18n,
  render: h => h(App)
}).$mount('#app')
