<template>
  <v-dialog v-model="isShowApiKeyConfigDialog" width="400" persistent>
    <v-card>
      <v-card-title class="popup-header">
        <span>Api Key Config</span>
        <v-spacer />
        <v-btn icon small dark @click="isShowApiKeyConfigDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-5">
        <div class="fill-height">
          <v-text-field
            v-model="lc60_api_key"
            label="Latconnect60 Api Key"
            append-outer-icon="mdi-content-copy"
            @click:append-outer="copyApiKey"
          ></v-text-field>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="primary" text @click="saveConfig">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { saveApiKey, getMe } from '@/backend'
export default {
  name: "ApiKeyConfigDialog",
  props: {
    currentUser: { type: Object, default: () => {} },
  },
  data() {
    return {
      isShowApiKeyConfigDialog: false,
      loading: false,
      lc60_api_key: null,
    };
  },
  methods: {
    openDialog() {
      this.lc60_api_key = this.currentUser.latconnect60_api_key 
      this.isShowApiKeyConfigDialog = true;
    },
    async saveConfig() {
      try {
        const { data } = await saveApiKey(this.lc60_api_key);
        const userInfo = await getMe();

        this.$store.commit('auth/SET_USER', userInfo.data.data)
        this.$store.commit("message/SHOW_SUCCESS", data.message);
      } catch (e) {
        this.$store.commit("message/SHOW_ERROR", e.message);
      } finally {
        this.lc60_api_key = null
        this.isShowApiKeyConfigDialog = false;
      }
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.lc60_api_key);
      this.$store.commit("message/SHOW_SUCCESS", 'Copied to clipboard');
    }
  },
};
</script>
