import Store from '@/store'
import config from '@/config.json'
import api from '@/api'
export async  function checkAuth (to, from, next) {
  try {
    if (to.query.token) {
      const token = to.query.token
      api.setToken(JSON.parse(window.atob(token)).access_token)
      next(to.path)
    }
    if (!api.getToken()) {
      throw "token"
    }
    let result = await api.get(`${config.backend_php}/me`)
    Store.commit('auth/SET_USER', result.data.data)
  } catch (e) {
    // if (e === "token" || (e.response && e.response.status === 500)) {
    //   console.log(e)
    // location.href = `https://test.eofactory.ai:3443/signIn.html?redirect=${location.origin}?token=`
    // }
  } finally {
    next()
  }
}
