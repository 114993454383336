var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":240,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('Avatar',_vm._g(_vm._b({staticClass:"touchable-highlight",class:_vm.customClasses,attrs:{"image":_vm.currentUser && _vm.currentUser.avatar
          ? _vm.currentUser.avatar
          : require('@/assets/images/default-avatar.jpg'),"name":(_vm.currentUser && _vm.currentUser.email) || 'Guest'}},'Avatar',attrs,false),on))]}}])},[_c('v-card',{staticStyle:{"min-width":"12.5em"}},[(_vm.currentUser)?[_c('v-card-text',{staticStyle:{"text-align":"center"}},[_c('Avatar',{attrs:{"image":_vm.currentUser && _vm.currentUser.avatar
              ? _vm.currentUser.avatar
              : require('@/assets/images/default-avatar.jpg'),"name":_vm.currentUser.email}}),_c('div',[_vm._v(" "+_vm._s(_vm.currentUser.email)+" ")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","small":"","color":"#526c80"},on:{"click":_vm.openApiKeyConfigDialog}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-key-variant")]),_vm._v(" Config ")],1),_c('v-spacer'),_c('LogoutButton',{attrs:{"text":"","small":""}})],1)]:[_c('v-card-text',{staticStyle:{"text-align":"center"}},[_c('Avatar',{attrs:{"image":require('@/assets/images/default-avatar.jpg'),"name":"Guest"}}),_c('div',{staticClass:"my-4"},[_vm._v("Guest")]),_c('LoginButton',{attrs:{"small":"","color":"primary"}})],1)]],2),_c('api-key-config-dialog',{ref:"apiKeyConfigRef",attrs:{"currentUser":_vm.currentUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }